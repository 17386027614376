$hls-color-elevation: 0,0,0;

:root {
  --hls-color-elevation: #{$hls-color-elevation };
}

%hls-elevation-1 {
  box-shadow: 0 2px 6px 0 rgba(var(--hls-color-elevation), .14),
    0 1px 2px 0 rgba(var(--hls-color-elevation), .08),
    0 0 1px 0 rgba(var(--hls-color-elevation), .06),
    0 0 0 0 rgba(var(--hls-color-elevation), .04);
}

%hls-elevation-2 {
  box-shadow: 0 8px 17px 0 rgba(var(--hls-color-elevation), .10),
    0 2px 5px 0 rgba(var(--hls-color-elevation), .06),
    0 1px 2px 0 rgba(var(--hls-color-elevation), .04),
    0 0 1px 0 rgba(var(--hls-color-elevation), .03);
}

%hls-elevation-3 {
  box-shadow: 0 10px 20px 0 rgba(var(--hls-color-elevation), .25),
    0 2px 6px 0 rgba(var(--hls-color-elevation), .30);
}

%shadows-form-input {
  box-shadow: inset 0 .1rem .3rem rgba(0, 0, 0, .2);
}

$hls-available-elevation-levels: 3;
