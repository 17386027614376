
@mixin _loadingPulse {
  animation: loadingPulseAnimation .75s ease-in-out alternate infinite;
  background-color: $hls-color-grey-20;
}

@mixin _borderRadius {
  border-radius: .4rem;
}

@keyframes loadingPulseAnimation {

  0% {
    opacity: 1;
  }

  100% {
    opacity: .75;
  }
}
